"use client";

import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useAxios from "./useAxios";

export enum RequestType {
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

interface IUseGenericMutation<Res, Req> {
  requestType?: RequestType;
  baseUrl: string;
  endpoint: string;
  onSuccess?: (data: Res) => void;
  onError?: (error: AxiosError<any>) => void;
  isFormData?: boolean;
}

const useGenericMutation = <Res, Req>({
  baseUrl,
  endpoint,
  requestType = RequestType.POST,
  isFormData = false,
  onSuccess,
  onError,
}: IUseGenericMutation<Res, Req>) => {
  const api = useAxios(baseUrl);

  if (isFormData) {
    api.defaults.headers.post["Content-Type"] = "multipart/form-data";
    api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    api.defaults.headers.post["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE, OPTIONS";
    api.defaults.headers.post["Access-Control-Allow-Headers"] = "Content-Type, Authorization, X-Requested-With";
    api.defaults.headers.post["Access-Control-Allow-Credentials"] = "true";
    api.defaults.headers.post["Access-Control-Max-Age"] = "1728000";
    api.defaults.headers.post["Access-Control-Expose-Headers"] = "Content-Length, X-JSON";
    api.defaults.headers.post["Access-Control-Allow-Headers"] = "Content-Type, Authorization, X-Requested-With";
  } else {
    api.defaults.headers.post["Content-Type"] = "application/json";
  }

  return useMutation({
    mutationFn: (req: Req) => {
      if (requestType === RequestType.DELETE) return api.delete<Res>(endpoint);
      if (requestType === RequestType.PUT) return api.put<Res>(endpoint, req);
      return api.post<Res>(endpoint, req);
    },
    onSuccess: (data) => {
      onSuccess && onSuccess(data.data);
    },
    onError(error: AxiosError<any>) {
      onError && onError(error);
    },
  });
};

export { useGenericMutation };
