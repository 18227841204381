import React, { useState, useEffect } from "react";

const StarsAndSparkles: React.FC = () => {
  const [elements, setElements] = useState<React.ReactNode[]>([]);
  const nbElements = 50;

  const shapes = ["sparkle"];
  const sizes = ["", "small"];
  const styles = ["", "alt", "alt2"];
  const animations = ["pulse", "pulse-1", "pulse-2", "pulse-3"];

  const rand = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

  useEffect(() => {
    const generateElements = (): void => {
      const newElements = Array.from({ length: nbElements }, (_, i) => {
        const classes =
          shapes[rand(0, shapes.length - 1)] +
          " " +
          sizes[rand(0, sizes.length - 1)] +
          " " +
          styles[rand(0, styles.length - 1)] +
          " " +
          animations[rand(0, animations.length - 1)];

        const style = {
          top: rand(0, 100) + "%",
          left: rand(0, 100) + "%",
        };

        return <div key={i} className={classes} style={style}></div>;
      });

      setElements(newElements);
    };

    generateElements();
  }, []);

  return <div>{elements}</div>;
};

export default StarsAndSparkles;
