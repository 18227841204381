import { jokes } from "@/utils/jokes";

type Props = {};

const Jokes = () => {
  const filteredJokes = jokes.filter((joke) => joke.type === "general");
  const joke = filteredJokes[Math.floor(Math.random() * filteredJokes.length)];

  return (
    <>
      <p className="font-bold">{joke.setup}</p>
      <p>{joke.punchline}</p>
    </>
  );
};

export default Jokes;
