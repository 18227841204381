"use client";

import { useRef } from "react";

import { CloseButton } from "@mantine/core";
import { IconCamera } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { Camera, CameraType } from "react-camera-pro";

interface CustomCameraProps {
  onCapture: (image: string) => void;
  onClosed: () => void;
}

const CustomCamera = ({ onCapture, onClosed }: CustomCameraProps) => {
  const camera = useRef<CameraType>(null);
  const router = useRouter();

  const captureImage = async () => {
    if (camera.current) {
      const image = camera.current.takePhoto();
      onCapture(image);
    }
  };

  return (
    <div className="fixed  w-full h-full z-[99999999999999] bg-black">
      <CloseButton className="absolute top-4 right-4 z-[999999999999999999999]" onClick={onClosed} />

      <Camera
        ref={camera}
        facingMode="environment"
        aspectRatio="cover"
        errorMessages={{
          noCameraAccessible: "No camera device accessible. Please connect your camera or try a different browser.",
          permissionDenied: "Permission denied. Please refresh and give camera permission.",
          switchCamera:
            "It is not possible to switch camera to different one because there is only one video device accessible.",
          canvas: "Canvas is not supported",
        }}
        videoReadyCallback={() => {
          console.log("Video feed ready.");
        }}
      />
      <div className="fixed bottom-0 right-0 flex w-full h-20 p-4 bg-gray-950/50">
        <div className="flex items-center justify-center w-full h-full">
          <button
            className="flex items-center justify-center p-4 text-white rounded-full bg-gray-950/50"
            onClick={() => captureImage()}
          >
            <IconCamera size={32} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomCamera;
