import { GameRecord } from '@/types/game-record';
import { create } from 'zustand'

interface PlayGameState {
    playGame: {
        fingerprint: string;
        sessionId: string;
        image: string;
    };
    setPlayGame: (state: Partial<PlayGameState['playGame']>) => void;
    setGameRecord: (gameRecord: GameRecord) => void;
}

export const usePlayGameStore = create<PlayGameState>((set) => ({
    playGame: {
        fingerprint: '',
        sessionId: '',
        image: '',
    },
    setPlayGame: (newState) => set((state) => ({ playGame: { ...state.playGame, ...newState } })),
    setGameRecord: (gameRecord) => set((state) => ({ ...state, gameRecord })),
}))