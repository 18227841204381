"use client";

import { ulid } from "ulid";
import { generateFingerprint } from "../utils/generate-fingerprint";
import { useEffect, useState } from "react";
import { useOnceCall } from "./use-once-call";

export const useFingerprint = () => {
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [fingerprint, setFingerprint] = useState("");

    useOnceCall(() => {
        // check if session id is already set
        if (sessionId) return;

        // check if session id is stored in local storage
        const storedSessionId = localStorage.getItem("session_id");
        if (storedSessionId) {
            setSessionId(storedSessionId);
            return;
        }

        // generate new session id
        const newSession = ulid()
        setSessionId(newSession);
        localStorage.setItem("session_id", newSession);
    });

    useOnceCall(() => {
        setFingerprint(generateFingerprint());
    });

    return { sessionId, fingerprint };
}