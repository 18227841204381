"use client";

import { CloseButton } from "@mantine/core";
import { IconSpeakerphone } from "@tabler/icons-react";
import Link from "next/link";
import { useState } from "react";
import Marquee from "react-fast-marquee";

type Props = {};

const HeaderBanner = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen && (
        <div
          id="header-banner"
          className="gap-4 items-center bg-zinc-900 flex px-2 py-1 border border-zinc-800 rounded-sm"
        >
          <IconSpeakerphone className="text-blue-200/60" />
          <Marquee play={true} pauseOnHover autoFill={false}>
            <p className="flex mr-8 font-sans text-xs text-blue-200/60">
              Find other great games near you.
              {/* Player ID-{Math.floor(Math.random() * 1000000)} {"  "} has just won the prize pool, 19:55 */}
              <span>
                <Link href="/winners" className="text-blue-800 font-medium ml-2 underline">
                  Discover now.
                </Link>
              </span>
            </p>
          </Marquee>
          <CloseButton onClick={() => setIsOpen(false)} />
        </div>
      )}
    </>
  );
};

export default HeaderBanner;
