import Image from "next/image";
import Marquee from "react-fast-marquee";

type Props = {};

const LogoAnimation = (props: Props) => {
  return (
    <div style={{ scale: 1.8 }} className="-rotate-12 z-0  md:w-full h-full md:h-[120%] bg-transparent  w-[200%]">
      <Marquee  className="ml-16 mt-2 md:mt-4" autoFill={true}>
        <LogoImage imageFile="/icons/1.jpg" />
        <LogoImage imageFile="/icons/2.jpg" />
        <LogoImage imageFile="/icons/3.jpg" />
        <LogoImage imageFile="/icons/4.jpg" />
        <LogoImage imageFile="/icons/5.jpg" />
      </Marquee>

      <Marquee  className="mt-2 md:mt-4" autoFill={true}>
        <LogoImage imageFile="/icons/5.jpg" />
        <LogoImage imageFile="/icons/4.jpg" />
        <LogoImage imageFile="/icons/3.jpg" />
        <LogoImage imageFile="/icons/2.jpg" />
        <LogoImage imageFile="/icons/1.jpg" />
      </Marquee>
      <Marquee className="ml-16 mt-2 md:mt-4" autoFill={true}>
        <LogoImage imageFile="/icons/1.jpg" />
        <LogoImage imageFile="/icons/2.jpg" />
        <LogoImage imageFile="/icons/3.jpg" />
        <LogoImage imageFile="/icons/4.jpg" />
        <LogoImage imageFile="/icons/5.jpg" />
      </Marquee>

      <Marquee className="mt-2 md:mt-4" autoFill={true}>
        <LogoImage imageFile="/icons/5.jpg" />
        <LogoImage imageFile="/icons/4.jpg" />
        <LogoImage imageFile="/icons/3.jpg" />
        <LogoImage imageFile="/icons/2.jpg" />
        <LogoImage imageFile="/icons/1.jpg" />
      </Marquee>
    </div>
  );
};

export default LogoAnimation;

interface LogoImageProps {
  imageFile: string;
}

const LogoImage = ({ imageFile }: LogoImageProps) => {
  return (
    <Image
      priority={true}
      alt="5"
      src={imageFile}
      width={300}
      height={300}
      className="md:h-32 shadow-lg h-16 md:w-32 w-16  
      mx-1 md:mx-2 rounded-2xl md:rounded-3xl"
    />
  );
};
