import crypto from 'crypto';

// Function to generate a browser fingerprint
export function generateFingerprint() {
    const fingerprint = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        colorDepth: screen.colorDepth,
        screenResolution: `${screen.width}x${screen.height}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hasSessionStorage: !!window.sessionStorage,
        hasLocalStorage: !!window.localStorage,
        hasIndexedDB: !!window.indexedDB,
        cpuCores: navigator.hardwareConcurrency,
        touchSupport: 'ontouchstart' in window,
        canvas: getCanvasFingerprint(),
        fonts: detectFonts(),
        audioFingerprint: getAudioFingerprint(),
    };

    console.log(fingerprint);


    const values = Object.values(fingerprint).join('');
    return crypto.createHash('sha256').update(values).digest('hex');
}

// Function to get canvas fingerprint
function getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const text = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if (ctx !== null) {
        ctx.textBaseline = 'top';
        ctx.font = '14px Arial';
        ctx.textBaseline = 'alphabetic';
        ctx.fillStyle = '#f60';
        ctx.fillRect(125, 1, 62, 20);
        ctx.fillStyle = '#069';
        ctx.fillText(text, 2, 15);
    }
    return canvas.toDataURL();
}

// Function to detect installed fonts
function detectFonts() {
    const baseFonts = ['monospace', 'sans-serif', 'serif'];
    const fontList = [
        'Arial', 'Helvetica', 'Times New Roman', 'Courier', 'Verdana',
        'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS',
        'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    const d = document.createElement('div');
    d.style.visibility = 'hidden';
    d.style.position = 'absolute';
    d.style.top = '-10000px';
    d.style.left = '-10000px';
    d.style.fontSize = '12px';
    d.innerHTML = 'abcdefghijklmnopqrstuvwxyz0123456789';
    document.body.appendChild(d);

    const defaultWidth: { [key: string]: number } = {};
    const defaultHeight: { [key: string]: number } = {};

    baseFonts.forEach(baseFont => {
        d.style.fontFamily = baseFont;
        defaultWidth[baseFont] = d.offsetWidth;
        defaultHeight[baseFont] = d.offsetHeight;
    });

    const detectedFonts = [];
    for (let font of fontList) {
        let isDetected = false;
        for (let baseFont of baseFonts) {
            d.style.fontFamily = `${font},${baseFont}`;
            if (d.offsetWidth !== defaultWidth[baseFont] ||
                d.offsetHeight !== defaultHeight[baseFont]) {
                isDetected = true;
                break;
            }
        }
        if (isDetected) {
            detectedFonts.push(font);
        }
    }

    document.body.removeChild(d);
    return detectedFonts;
}

// Function to get audio fingerprint
function getAudioFingerprint() {
    const audioContext = new (window.AudioContext)();
    const oscillator = audioContext.createOscillator();
    const analyser = audioContext.createAnalyser();
    const gainNode = audioContext.createGain();
    const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);

    gainNode.gain.setValueAtTime(0, audioContext.currentTime);
    oscillator.type = 'triangle';
    oscillator.connect(analyser);
    analyser.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.start(0);

    let audioData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(audioData);

    oscillator.stop();

    return audioData.slice(0, 16).join(',');
}

